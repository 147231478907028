import styled from 'styled-components'
import BreakpointUp from '../Media/BreakpointUp'
import BreakpointDown from "../Media/BreakpointDown"

export const ListInfoContainer = styled.div`
    margin:0 auto;
    max-width:1496px;
    width:100%;
`
export const ListInfo = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin:0 -5px;
    ${BreakpointUp.xl` 
        margin:0 -10px;
    `}
    ${BreakpointUp.xxl` 
        margin:0 -15px;
    `}
`
export const ListInfoItem = styled.div`
    position: relative;
    width: 100%;
    padding:0 5px;
    ${BreakpointUp.md` 
        flex: 0 0 50%;
        max-width: 50%;
    `}
    ${BreakpointUp.lg` 
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
    ${BreakpointUp.xl` 
        padding:0 10px;
    `}
    ${BreakpointUp.xxl` 
        padding:0 15px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
`
export const CardInfo = styled.div`
    border:1px solid #98DFFF;
    box-shadow:0 15px 45px rgba(152,223,255,0.20);
    display: flex;
    flex-wrap: wrap;
    border-radius: 6px;
    align-items: baseline;
    height: calc(100% - 30px);
    margin-bottom:30px;
    padding:30px 10px;
    ${BreakpointUp.xl` 
        align-items: center;
        `}
    @media (min-width: 1366px){
        padding:30px 15px;
    }
    ${BreakpointUp.xxl` 
        padding:60px 30px;
    `}
    ${BreakpointDown.md`
        text-align:center;
        padding:15px;
        height: auto;
        margin-bottom:15px;
    `}
`
export const CardIcon = styled.div`
    position: relative;
    padding:0 10px;
    width:100%;
    .gatsby-image-wrapper{
        width: 34px;
        border-radius: 0;
        box-shadow:none;
        margin-bottom:0;
        @media (min-width: 1366px){
            width: auto;
        }
    }
    ${BreakpointUp.xl` 
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;

    `}

`
export const CardDesc = styled.div`
    position: relative;
    padding:0 10px;
    width:100%;
    ${BreakpointUp.xl` 
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    `}
    strong{
        color:#000;
        font-family: 'Barlow', sans-serif;
        font-weight: 700;
        margin:0 0 15px; 
        letter-spacing: 0.3px;
        font-size: 18px;
        line-height: 24px;
        ${BreakpointUp.md` 
            font-size: 20px;
            line-height: 24px;
        `}
        ${BreakpointUp.xxl` 
            font-size: 22px;
            line-height: 28px;
        `}
    }
    p{
        margin-bottom:0;
        color:#666;
        > a{
            color:#666;
            font-weight: 400;
            &:hover, &:focus{
                color:#033366;
            }
        }
    }
`