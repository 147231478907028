import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const LinkDefaultBtn = styled(BaseButton)`		
    color:#00254D;
    padding-left:0 !important;
    padding-right:0 !important;
	& .icon{
		> svg{
			fill:#00254D;
		}
	}
	&:hover{
        color:#EC1C24;
        & .icon{
            > svg{
                fill:#EC1C24;
            }
	    }
	}
`

const LinkDefaultButton = (props) => {
	const { icon, text, size } = props;
	return(
		<LinkDefaultBtn className={`btn ${size ? `btn-${size}` : ''} `}>
            <>
                { text &&
                    <span className='text'>{text}</span>
                }
                { icon &&
                    <span className='icon'>{icon}</span>
                }
            </>
		</LinkDefaultBtn>
	)
}

export default LinkDefaultButton