import React from "react"
import styled from "styled-components"
import Layout from "../layouts"
import Seo from "../components/seo"
import { Link } from "gatsby"
import {
  BreadCrumb,
  Container,
  Section,
  SectionPageTitle,
  SectionTitle,
  SectionInnerWhite,
} from "../components/Section"
import {
  ListInfoContainer,
  ListInfo,
  ListInfoItem,
  CardInfo,
  CardIcon,
  CardDesc,
} from "../components/Benefits"
import ContactHero from "../images/contact-bg.jpg"
import { StaticImage } from "gatsby-plugin-image"
import GetInTouch from "../components/GetInTouch"

const Hero = styled.div`
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    background: url(${ContactHero});
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    opacity: 0.8;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    background: rgba(3, 51, 102, 0.92);
    width: 100%;
    height: 100%;
  }
  .form {
    .form-group {
      .form-control {
        background-color: transparent;
        color: #fff;
        font-weight: 400;
        &::placeholder {
          color: #fff;
        }
        &:focus,
        &:active {
          border-color: #fff;
        }
      }
      textarea {
        max-height: 300px;
      }
    }
  }
`

const HeroCaption = styled.div`
  position: relative;
  z-index: 1;
  padding: 60px 30px;
  @media (min-width: 1200px) {
    padding: 100px 30px;
  }
  @media (min-width: 1600px) {
    padding: 180px 30px;
  }
  .btn {
    color: #fff;
    & .icon {
      > svg {
        fill: #fff;
      }
    }
    &:hover {
      color: #d51333;
      & .icon {
        > svg {
          fill: #d51333;
        }
      }
    }
  }
`
const CardIcons = styled(CardIcon)`
  margin-bottom: 10px;
  width: 100%;
  @media (min-width: 1280px) {
    margin-bottom: 0;
    width: auto;
  }
`

const ContactUsPage = ({ location }) => (
  <Layout isHeader={true} location={location}>
    <Seo
      title="Contact Us - Get your Metal Building Quote Today!"
      description="Looking for metal carports, garages, barns RVs, or commercial buildings? Contact us and get a quote on metal building today! "
    />
    <Section xpt="114px" mpt="94px" pt="64px" pb="0" bgColor="#F0F8FF">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <Hero>
          <HeroCaption>
            <BreadCrumb
              position="static"
              left="0px"
              translate="0"
              className="white"
            >
              <Link to="/">Home </Link>
              <span>Contact Us</span>
            </BreadCrumb>
            <SectionPageTitle color="#fff" maxWidth="876px" mb="30px">
              Get In Touch - For All Enquires, Please Email Us Using The Form
              Below.
            </SectionPageTitle>
            <GetInTouch location={location} />
          </HeroCaption>
        </Hero>
      </Container>
    </Section>
    <Section xpt="0" mpt="0" pt="0" xpb="60px" pb="30px" bgColor="transparent">
      <Container maxWidth="100%" pl="3%" pr="3%">
        <SectionInnerWhite xpl="4.166%" xpr="4.166%">
          <SectionTitle maxWidth="840px" mb="40px">
            Contact Us - For all enquires, please email us using the form below.
          </SectionTitle>
          <ListInfoContainer>
            <ListInfo>
              <ListInfoItem>
                <CardInfo>
                  <CardIcons>
                    <StaticImage
                      src="../svg/location.svg"
                      placeholder="blurred"
                      alt="location"
                    />
                  </CardIcons>
                  <CardDesc>
                    <strong>Address</strong>
                    <p>796 W Lebanon St. Mount Airy, NC 27030</p>
                  </CardDesc>
                </CardInfo>
              </ListInfoItem>
              <ListInfoItem>
                <CardInfo>
                  <CardIcons>
                    <StaticImage
                      src="../svg/email.svg"
                      placeholder="blurred"
                      alt="email"
                    />
                  </CardIcons>
                  <CardDesc>
                    <strong>Email Address</strong>
                    <p>
                      <a href="mailto:sales@libertymetalstructures.com">
                        sales@libertymetalstructures.com
                      </a>
                    </p>
                  </CardDesc>
                </CardInfo>
              </ListInfoItem>
              <ListInfoItem>
                <CardInfo>
                  <CardIcons>
                    <StaticImage
                      src="../svg/send.svg"
                      placeholder="blurred"
                      alt="send"
                    />
                  </CardIcons>
                  <CardDesc>
                    <strong>Contact Number</strong>
                    <p>
                    <a href="tel:8004407309">(800) 440-7309</a>
                    </p>
                  </CardDesc>
                </CardInfo>
              </ListInfoItem>
            </ListInfo>
          </ListInfoContainer>
        </SectionInnerWhite>
      </Container>
    </Section>
  </Layout>
)

export default ContactUsPage
